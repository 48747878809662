import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import EmojiPicker, { Theme, EmojiClickData } from 'emoji-picker-react';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { io } from 'socket.io-client';
import { faker } from '@faker-js/faker';
import { v4 } from 'uuid';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import swalError from '~/utils/swalError';

import { Container } from './styles';
import Textarea from '~/components/Textarea';

import emoji from '~/assets/icons/button-emoji.svg';
import webinarMessages from '~/assets/messages/webinar-tb-page.json';
import usersData from '~/assets/messages/users.json';

interface IUserData {
  id: string;
  avatar_id: string;
  name: string;
  avatar_url: string;
}

interface ILiveChat {
  chatId: string;
  active: boolean;
  onClick(): void;
  userData?: IUserData;
  videoTime: number;
  initWebinar: boolean;
}

interface IChat {
  id: string;
  type: string;
  referrer_code: string;
}

interface IMessageResponse {
  id: string;
  temp_user_id: string;
  name: string;
  content: string;
  avatar: {
    avatar_url: string;
  };
  time: number;
}

interface IMessage {
  id: string;
  user_id: string;
  me: boolean;
  participants: boolean;
  avatar: string;
  name: string;
  text: string;
  time: number;
}

interface ITempUserData {
  id?: string;
  avatar_id?: string;
  name: string;
}

interface IParams {
  reference: string;
}

interface IAttendee {
  id: string;
  name: string;
  avatar: string;
  type: string;
}

const LiveChat: React.FC<ILiveChat> = ({
  chatId,
  active,
  onClick,
  userData,
  videoTime,
  initWebinar,
}) => {
  const chatRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [chatParticipants, setChatParticipants] = useState('chat');
  const [chat, setChat] = useState({} as IChat);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [meMessage, setMeMessage] = useState('');
  const [emojiWindowActive, setEmojiWindowActive] = useState(false);
  const [tempUserData, setTempUserData] = useState({} as ITempUserData);
  const [messagesReal, setMessagesReal] = useState<IMessage[]>([]);
  const [messagesWebinar, setMessagesWebinar] = useState<IMessage[]>([]);
  const [attendees, setAttendees] = useState<IAttendee[]>([]);

  useEffect(() => {
    if (userData) {
      setAttendees((state) => [
        {
          id: userData.id,
          name: userData.name,
          avatar: userData.avatar_url,
          type: 'Member',
        },
        ...state,
      ]);
    }
  }, [userData]);

  useEffect(() => {
    if (chatRef.current && chatParticipants === 'chat') {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages, active, chatParticipants]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL as string, {
      reconnection: true,
    });

    socket.on('connect', () => {
      console.log('socket connected');
      socket.emit('join-room', { roomID: chatId });
    });

    socket.on('user-disconnected', (data: any) => {
      console.log('user disconnected-- closing peers', data);
    });

    socket.on('disconnect', () => {
      console.log('socket disconnected --');
    });

    socket.on('error', (err: any) => {
      console.log('socket error --', err);
    });

    socket.on('new-message', (data: IMessageResponse) => {
      setMessages((state) => {
        const checkMessage = state.find((message) => message.id === data.id);
        if (!checkMessage && tempUserData.id !== data.temp_user_id) {
          const newMessage: IMessage = {
            id: data.id,
            user_id: data.temp_user_id,
            me: tempUserData.id === data.temp_user_id,
            participants: true,
            avatar: data.avatar.avatar_url,
            name: tempUserData.id === data.temp_user_id ? 'You' : data.name,
            text: data.content,
            time: data.time,
          };
          return [...state, newMessage];
        }
        return state;
      });
    });
  }, [chatId, tempUserData.id]);

  useEffect(() => {
    const tempUserDataString = localStorage.getItem(
      '@AUTOAFFILIATE:tempUserData'
    );
    if (!tempUserDataString) {
      const tempUserDataAux = {
        id: userData?.id,
        name: userData?.name || faker.person.fullName(),
        avatar_id: userData?.avatar_id,
      };
      localStorage.setItem(
        '@AUTOAFFILIATE:tempUserData',
        JSON.stringify(tempUserDataAux)
      );
      setTempUserData(tempUserDataAux);
    } else {
      setTempUserData(JSON.parse(tempUserDataString));
    }
  }, [userData?.avatar_id, userData?.id, userData?.name]);

  useEffect(() => {
    api.get<IChat>(`chats/${chatId}`).then(async (response) => {
      const responseMessages = await api.get<IMessageResponse[]>(
        `chats-messages/${response.data.id}`
      );
      const data = responseMessages.data.map((message) => ({
        id: message.id,
        user_id: message.temp_user_id,
        me: tempUserData.id === message.temp_user_id,
        participants: true,
        avatar: message.avatar.avatar_url,
        name: tempUserData.id === message.temp_user_id ? 'You' : message.name,
        text: message.content,
        time: message.time || 0,
      }));
      setMessagesReal(data);
      setChat(response.data);
    });
  }, [chatId, params.reference, tempUserData.id]);

  useEffect(() => {
    const video = document.getElementById('video') as HTMLVideoElement;
    if (video) {
      const messagesRealData = messagesReal.filter(
        (message) => message.time <= video.currentTime
      );
      const messagesData = messagesWebinar.filter(
        (message) => message.time <= video.currentTime
      );
      setMessages((state) => [...state, ...messagesRealData, ...messagesData]);
    }
  }, [messagesReal, messagesWebinar]);

  // TALVEZ TENHA QUE USAR FILTER, MAS FIND ESTÁ FUNCIONANDO!
  useEffect(() => {
    if (messagesReal.length > 0) {
      const minTime = Math.floor(videoTime);
      const maxTime = Math.ceil(videoTime);
      const messageData = messagesReal.find(
        (message) =>
          message.time >= minTime &&
          message.time <= maxTime &&
          !messages.find((checkMessage) => checkMessage.id === message.id)
      );
      if (messageData) {
        setMessages((state) => [...state, messageData]);
      }
    }
  }, [messages, messagesReal, videoTime]);

  useEffect(() => {
    if (messagesWebinar.length > 0) {
      const minTime = Math.floor(videoTime);
      const maxTime = Math.ceil(videoTime);
      const messageData = messagesWebinar.find(
        (message) =>
          message.time >= minTime &&
          message.time <= maxTime &&
          !messages.find((checkMessage) => checkMessage.id === message.id)
      );
      if (messageData) {
        setMessages((state) => [...state, messageData]);
      }
    }
  }, [messages, messagesWebinar, videoTime]);

  const convertToSeconds = useCallback((timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  }, []);

  useEffect(() => {
    const data = webinarMessages.map((message) => {
      return {
        id: v4(),
        user_id: message.user_id,
        me: tempUserData.id === message.user_id,
        participants: true,
        avatar: message.avatar,
        name: tempUserData.id === message.user_id ? 'You' : message.name,
        text: message.text,
        time: convertToSeconds(message.time),
      };
    });
    setMessagesWebinar(data);
  }, [convertToSeconds, tempUserData.id]);

  useEffect(() => {
    const users = messages
      .filter((message, index, self) => {
        return index === self.findIndex((o) => o.user_id === message.user_id);
      })
      .map((message) => ({
        id: message.user_id,
        avatar: message.avatar,
        name: message.name,
        type: message.name.includes('Host') ? 'Host' : 'Member',
      }));

    const attendeesData = sessionStorage.getItem(
      `@AutoAffiliate:attendees${location.pathname}`
    );

    if (attendeesData) {
      users.push(...(JSON.parse(attendeesData) as IAttendee[]));
    }

    setAttendees((state) => {
      const newUsers = users.filter(
        (user) => !state.find((attendee) => attendee.id === user.id)
      );

      return [...state, ...newUsers];
    });
  }, [location.pathname, messages, tempUserData.id]);

  useEffect(() => {
    const addAttendeeWithRandomInterval = (index: number) => {
      if (!initWebinar) {
        const interval = Math.random() * 4000;

        const intervalId = setTimeout(() => {
          if (index >= usersData.length) {
            clearInterval(intervalId);
          } else {
            const newAttendee: IAttendee = {
              id: usersData[index].user_id,
              name: usersData[index].name,
              avatar: usersData[index].avatar,
              type: usersData[index].name.includes('Host') ? 'Host' : 'Member',
            };
            setAttendees((state) => {
              const checkUser = state.find(
                (attendee) => attendee.id === newAttendee.id
              );
              if (!checkUser) {
                return [...state, newAttendee];
              }
              return state;
            });
            addAttendeeWithRandomInterval(index + 1);
          }
        }, interval);
      } else {
        const data = usersData.map((dataUser) => ({
          id: dataUser.user_id,
          name: dataUser.name,
          avatar: dataUser.avatar,
          type: dataUser.name.includes('Host') ? 'Host' : 'Member',
        }));
        if (userData) {
          const me = {
            id: userData.id,
            name: userData.name,
            avatar: userData.avatar_url,
            type: 'Member',
          };
          setAttendees([me, ...data]);
        } else {
          setAttendees(data);
        }
      }
    };

    addAttendeeWithRandomInterval(0);
  }, [initWebinar, userData]);

  useEffect(() => {
    console.log(location.pathname);
    sessionStorage.setItem(
      `@AutoAffiliate:attendees${location.pathname}`,
      JSON.stringify(attendees)
    );
  }, [attendees, location.pathname]);

  const elementClass = document.querySelector('.height-chat');

  if (elementClass) {
    const height = elementClass.scrollHeight;
    elementClass.scrollTo(0, height);
  }

  const handleButtonOpenEmoji = useCallback(() => {
    setEmojiWindowActive(!emojiWindowActive);
  }, [emojiWindowActive]);

  const handlePicker = useCallback(
    (emojiData: EmojiClickData) => {
      setMeMessage(meMessage + emojiData.emoji);
    },
    [meMessage]
  );

  const handleMeMessage = useCallback((e) => {
    setMeMessage(e);
  }, []);

  const handleKeyPress = useCallback(
    (e) => {
      if (
        (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') &&
        !e.shiftKey
      ) {
        e.preventDefault();
        if (meMessage) {
          formRef.current?.submitForm();
        }
      }
    },
    [meMessage]
  );

  const handleChatParticipants = useCallback((e) => {
    setChatParticipants(e);
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          message: yup.string().required('Message is required'),
        });

        await schema.validate(data, { abortEarly: false });

        const video = document.getElementById('video') as HTMLVideoElement;

        const formData = {
          chat_id: chat.id,
          avatar_id: tempUserData.avatar_id,
          temp_user_id: tempUserData.id,
          name: tempUserData.name,
          content: meMessage,
          time: video?.currentTime || 0,
        };

        const response = await api.post('chats-messages', formData);

        if (!tempUserData.id) {
          const newTempUserData = {
            id: response.data.temp_user_id,
            avatar_id: response.data.avatar_id,
            name: tempUserData.name,
          };
          setTempUserData(newTempUserData);
          localStorage.setItem(
            '@AUTOAFFILIATE:tempUserData',
            JSON.stringify(newTempUserData)
          );
        }

        setMessages((state) => [
          ...state,
          {
            id: response.data.id,
            user_id: response.data.temp_user_id,
            me: true,
            participants: false,
            avatar: response.data.avatar.avatar_url,
            name: 'You',
            text: meMessage,
            time: video?.currentTime || 0,
          },
        ]);

        formRef.current?.reset();
        setMeMessage('');
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          swalError({
            message:
              'Looks like what you were trying to do didn’t work, please try again.',
            textButton: 'Try Again',
          });
        }
      }
    },
    [
      chat.id,
      meMessage,
      tempUserData.avatar_id,
      tempUserData.id,
      tempUserData.name,
    ]
  );

  const chatMessages = useMemo(() => {
    let newMessages = messages.filter(
      (obj, index, self) =>
        index ===
        self.findIndex((o) => o.name === obj.name && o.text === obj.text)
    );

    const data = newMessages.find((message) => message.name.includes('(Host)'));
    if (data) {
      newMessages = newMessages.map((message) => {
        if (data.user_id === message.user_id) {
          return {
            ...message,
            avatar: data.avatar,
            name: data.name,
          };
        }
        return message;
      });
    }

    return newMessages.sort((a, b) => a.time - b.time);
  }, [messages]);

  return (
    <Container className="d-lg-flex flex-lg-column h-lg-100 py-3 mt-5 mt-lg-0">
      {active && (
        <>
          <div className="d-flex justify-content-evenly chat my-4">
            <button
              type="button"
              className={`${chatParticipants === 'chat' && 'chat-active'}`}
              onClick={() => {
                handleChatParticipants('chat');
              }}
            >
              <span>Chat</span>
            </button>
            <button
              type="button"
              className={`${
                chatParticipants === 'participants' && 'chat-active'
              }`}
              onClick={() => handleChatParticipants('participants')}
            >
              <span>Attendees</span>
            </button>
            <span className="d-flex align-items-center h6 fw-600 mb-0 number-participants">
              {attendees.length.toString().padStart(2, '0')}
            </span>
            <button
              type="button"
              onClick={onClick}
              className="d-none d-lg-block bg-transparent border-0 arrow"
            >
              <AiOutlineCloseCircle color="#777" size={30} />
            </button>
          </div>
          {chatParticipants === 'chat' && (
            <>
              <div
                ref={chatRef}
                className="px-4 me-2 overflow-auto mb-lg-4 vh-67"
              >
                {chatMessages.map((element) => (
                  <div
                    className={`${
                      element.me && 'justify-content-end'
                    } d-flex messages mt-4`}
                  >
                    {!element.me && (
                      <img src={element.avatar} alt="Avatar" className="me-2" />
                    )}
                    <div className={`${element.me && 'text-end'}`}>
                      <span
                        className={`${
                          element.me ? 'name-you' : 'name-participants'
                        } d-block name-chat`}
                      >
                        {element.name}
                      </span>

                      <div
                        className={`px-4 py-3 mt-1 ${
                          element.me ? 'bg-you' : 'bg-participants'
                        }`}
                      >
                        <p className="text-white">{element.text} </p>
                      </div>
                    </div>
                    {element.me && (
                      <img src={element.avatar} alt="Avatar" className="ms-2" />
                    )}
                  </div>
                ))}{' '}
              </div>
            </>
          )}
          {chatParticipants === 'participants' && (
            <>
              <div className="messages px-4 me-2 overflow-auto mb-lg-4 vh-67">
                {attendees.map((participant) => (
                  <div
                    className={`d-flex align-items-center ps-2 pe-3 py-2 my-2 ${
                      participant.id === tempUserData.id ||
                      participant.id === tempUserData.id
                        ? 'bg-you-attendees'
                        : 'bg-attendees'
                    } `}
                  >
                    <img src={participant.avatar} alt="Avatar" />
                    <span className="ms-3 name">{participant.name}</span>
                    <span className="ms-auto type">{participant.type}</span>
                  </div>
                ))}
              </div>
            </>
          )}
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div className="px-4 emojis-mobile">
              <EmojiPicker
                onEmojiClick={handlePicker}
                autoFocusSearch={false}
                searchDisabled
                skinTonesDisabled
                theme={Theme.DARK}
                width="100%"
                height={emojiWindowActive ? 350 : 0}
              />
            </div>
            <div className="d-flex d-lg-block mt-3 mt-lg-auto px-3 px-sm-4">
              <div className="order-1 order-lg-0 d-flex d-lg-block align-items-center text-end ms-3 ms-lg-0 me-lg-3 mb-lg-2 pt-0 pt-lg-2">
                <button
                  type="button"
                  className="d-flex align-items-center bg-input p-2 ms-lg-auto"
                  onClick={handleButtonOpenEmoji}
                >
                  {emojiWindowActive ? (
                    <IoMdClose size={25} color="#000" />
                  ) : (
                    <img src={emoji} alt="Emoji" />
                  )}
                </button>
              </div>
              <div className="order-0 order-lg-1 bg-input p-3 d-flex message-input">
                <Textarea
                  className="bg-transparent w-75 w-sm-100 border-0 h-100"
                  name="message"
                  rows={1}
                  placeholder="Write message here..."
                  onChange={(e) => {
                    handleMeMessage(e.target.value);
                  }}
                  onKeyPress={handleKeyPress}
                  value={meMessage}
                />
                <button
                  type="submit"
                  className="border-0 p-2 ms-auto ms-lg-3"
                  disabled={meMessage === ''}
                >
                  <RiSendPlane2Fill size={25} color="#fff" />
                </button>
              </div>
            </div>
          </Form>
        </>
      )}
    </Container>
  );
};

export default LiveChat;
